<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 提交金额
 * @Date: 2020-11-08 11:38:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-30 23:48:11
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/comeOn/AmountSubmitted.vue
-->
<template>
  <div class="content">
    <div class="order_details">
      <div class="or_title">加油订单</div>
      <ul>
        <li><span>油站名称</span><span>{{ query.gas_name }}</span></li>
        <li><span>油号/枪号</span><span>{{ query.name + '#' }} {{ query.gun_no }}号枪</span></li>
        <li><span>金额
          <input
            v-model="price"
            type="number"
            placeholder="请输入消费金额"
            @input="choiceInp($event)"
          ></span><span>{{
          literTitle
        }}</span></li>
      </ul>
    </div>
    <div class="Discount order_details">
      <div class="or_title">优惠奖励</div>
      <ul class="dis_list">
        <li><span>优惠直降</span><span>¥ {{ plummets | pr_status }}</span></li>
        <li><span>渠道服务费</span><span>¥ {{ serviceFee | pr_status }}</span></li>
        <li>
          <span>优惠券</span>
          <span class="icon-text" @click="showPopup"><i>{{ promotionDesc || '未使用优惠券' }}</i></span></li>
      </ul>
    </div>

    <van-popup
      v-model="show"
      closeable
      position="bottom"
      close-icon-position="top-left"
      :style="{ height: '50%' }"
      @close="couponClose"
    >
      <h3 class="coupon-title">优惠券</h3>
      <template v-if="couponList.length > 0">
        <div class="coupon-con">
          <div v-for="(item, index) in couponList" :key="index" class="item" @click="selectConpon(index,item)">
            <div v-if="item.promotionType === 1" class="item-left">
              <strong><i>¥</i>{{ item.discount }}</strong>
              <span>满{{ item.amount }}元</span>
            </div>
            <div v-if="item.promotionType === 2" class="item-left">
              <strong><i>¥</i>{{ item.discount }}</strong>
              <span>立减</span>
            </div>
            <div v-if="item.promotionType === 3" class="item-left">
              <strong>{{ item.discount }} <i>折</i></strong>
              <span>折扣</span>
            </div>
            <div class="item-right">
              <i v-show="index === conItemIndex" class="check" />
              <strong>{{ item.promotionDesc }}</strong>
              <span>有效期至{{ item.endTimestamp }}</span>
            </div>
          </div>
        </div>
        <div class="coupon-btn">
          <van-button :disabled="couponList.length === 0" type="danger" size="small" block @click="conponBtn">确定
          </van-button>
        </div>
      </template>
      <template v-else>
        <div class="coupon-null">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-05-28/16/yuelvhui4zJQuZvXpn1622192373.png"
          >
          <span>暂无优惠券</span>
        </div>
      </template>
    </van-popup>

    <van-popup v-model="isPromotionValue" round closeable style="width:80%">
      <div class="confir_box">
        <div class="confir_con">
          <van-icon name="warning" color="#F7263C" size="30" />
          <div class="confir_txt3">
            <span>{{ aleartTips }}</span>
          </div>
        </div>
        <div class="ok_box">
          <div @click="isPromotionValue = false">取消</div>
          <div @click="next_step">去开通</div>
        </div>
      </div>
    </van-popup>

    <div class="rule" @click="rules()">
      <van-icon name="warning" color="#F7263C" size="15" />
      <span>优惠规则</span>
    </div>
    <div class="text-tip">
      {{ tips }}
    </div>
    <div class="Price">
      <ul>
        <li
          v-for="(item,index) in pricelist"
          :key="index"
          :class="price === item ?'active':''"
          @click="choice(item,index)"
        >¥{{ item }}
        </li>
      </ul>
    </div>
    <van-row class="total">
      <van-col :span="18" class="total_cen">
        <van-row type="flex" class="cen_le">
          <van-col :span="11" class="cen_t1">合计待支付</van-col>
          <van-col :span="7" class="cen_t2">¥{{ payAmount | pr_status }}</van-col>
          <van-col :span="6">
            <van-dropdown-menu direction="up">
              <van-dropdown-item ref="item" title="明细">
                <div class="total_box">
                  <ul>
                    <li><span>总金额</span><span>¥{{ payAmount | pr_status }}</span></li>
                    <li><span>优惠直降</span><span>¥{{ plummets | pr_status }}</span></li>
                    <!-- <li>
                        <span>优惠劵</span
                        ><span>¥{{ couponsPrice | pr_status }}</span>
                      </li> -->
                    <li><span>渠道服务费</span><span>¥{{ serviceFee | pr_status }}</span></li>
                  </ul>
                </div>
              </van-dropdown-item>
            </van-dropdown-menu>
          </van-col>
        </van-row>
      </van-col>
      <van-col :span="6" class="tot_ok" @click="getCreateOilOrder()">确认支付</van-col>
    </van-row>
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="@/assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Cell, CellGroup, Col, DropdownItem, DropdownMenu, Icon, Popup, Row, Toast } from 'vant'
import { formatNumber } from '@/utils/common/utils'

Vue.use(Icon)
  .use(Toast)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Cell)
  .use(CellGroup)
  .use(Col)
  .use(Row)
  .use(Popup)
  .use(Button)
import {
  isPromotion,
  getXJComeOnPayData,
  getOrderPromotionList
} from '@/services/comeOn.js'
import { closeShare } from '@/utils/wx-api'
import { getusernew } from '@/services/userApi'

export default {
  filters: {
    pr_status(v) {
      if (v === '') {
        return '0.00'
      } else {
        return formatNumber(v, 2, '.', ',')
      }
    }
  },

  data() {
    return {
      query: {},
      pricelist: [],
      price: '',
      literTitle: '',
      litre: '',
      userInfo: '',
      amount: '',
      plummets: '', // 优惠直降
      serviceFee: '', // 渠道服务费
      payAmount: '',
      couponsPrice: '',
      phone: '',
      show: false, // 优惠券遮罩层
      showimg: false,
      topclose: true,
      couponList: [],
      conItemIndex: '',
      allowanceId: '', // 优惠券信息
      promotionType: '', // 1 '满减', 2 '立减', 3 '折扣'
      discount: '', // 优惠券金额
      promotionDesc: '', // 优惠券-描述
      couponItem: {},
      isPromotionValue: false, // 选择优惠券时判断是否可以使用 0 不可用(弹框提示)  1 可用
      isPromotionNum: '',
      aleartTips: '', // 弹框提示
      tips: '', // 页面提示
      confirmation: false,
      mobile: ''
    }
  },
  created() {
    this.query = this.$route.query
    this.pricelist = this.query.pricelist
    this.phone = localStorage.getItem('mobile')
    closeShare()// 禁用分享
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {

    /**
     * 获取用户信息
     * 2021-07-23 17:08:55
     * @author SnowRock
     */
    getUserInfo() {
      getusernew().then(res => {
        const { code, data } = res
        if (code === 200) {
          const { mobile } = data
          this.mobile = mobile
          this.getIsPromotion()
        }
      })
    },
    // 继续支付
    next_step() {
      this.isPromotionValue = false
      this.$router.push({
        path: '/MustBuy'
      })
    },

    // 关闭-优惠券弹框
    couponClose() {
      this.show = false
      this.couponList = []
    },

    // 使用优惠券
    conponBtn() {
      this.show = false
      if (this.couponItem) { // 选中优惠券信息
        const { allowanceId, promotionType, promotionDesc } = this.couponItem
        this.allowanceId = allowanceId
        this.promotionType = promotionType
        this.promotionDesc = promotionDesc
      } else {
        this.allowanceId = ''
        this.promotionType = ''
        this.promotionDesc = ''
      }

      this.getOilData()// 查询价格及升
    },
    // 选择优惠券
    selectConpon(index, item) {
      if (this.conItemIndex === index) { // 当前是否选中
        this.conItemIndex = ''
        this.couponItem = '' // 优惠券信息
      } else {
        this.conItemIndex = index
        this.couponItem = item
      }
    },
    // 优惠券弹框
    showPopup() {
      if (this.isPromotionNum === 0) {
        if (this.isPromotionValue === false) {
          this.isPromotionValue = true
          return
        }
      }
      if (this.price !== '') {
        this.show = true
        this.getUserPromotion()
      } else {
        Toast('请输入消费金额')
        return
      }
    },
    isclose(t) {
      this.topclose = t
    },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    // 点击固定金额查询
    choice(item, index) {
      this.price = this.pricelist[index]
      this.getOilData()
      this.getUserPromotion()
      this.allowanceId = ''
      this.promotionType = ''
      this.promotionDesc = ''
      this.conItemIndex = ''
      this.couponItem = '' // 优惠券信息
    },
    // 输入金额查询
    choiceInp() {
      this.getOilData()
    },
    // 查询价格及升
    getOilData() {
      getXJComeOnPayData({
        storeId: this.query.gas_id, // 油站编号
        mid: localStorage.getItem('uid'), // 用户id
        mobile: this.mobile, // 用户手机号
        amount: this.price, // 金额（元）
        name: this.query.name + '#', // 油号名称
        allowanceId: this.allowanceId // 优惠券信息
      }).then((res) => {
        if (res.code === 200) {
          this.literTitle = res.data.literTitle
          this.litre = res.data.litre
          this.amount = res.data.amount
          this.payAmount = res.data.payAmount
          this.plummets = res.data.promotionAmount // 优惠直降
          this.serviceFee = res.data.serviceFee // 渠道服务费
          this.couponsPrice = res.data.couponsPrice // 优惠价格
        } else {
          this.litre = ''
          this.literTitle = ''
        }
      })
    },
    // 优惠规则
    rules() {
      this.$router.push({
        path: '/PreferentialRules',
        query: {}
      })
    },

    /**
     * @description 领券接口
     * @author zhounianlai
     * @param {String} mid;// 用户ID
     * @param {String} mobile;// 手机号
     * @param {String} storeId;// 油站id
     * @param {String} amount;// 订单金额 元
     */

    getUserPromotion() {
      getOrderPromotionList({
        mid: localStorage.getItem('uid'),
        mobile: this.mobile,
        storeId: this.query.gas_id,
        amount: this.price
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.length > 0) {
            this.couponList = res.data
          } else {
            this.couponList = []
          }
        } else {
          this.couponList = []
        }
      })
    },

    /**
     * @description 是否可以使用优惠券
     * @author zhounianlai
     * @param {String} mid;// 用户ID
     * @param {String} mobile;// 手机号
     */

    getIsPromotion() {
      isPromotion({
        mid: localStorage.getItem('uid'),
        mobile: this.mobile
      }).then((res) => {
        if (res.code === 200) {
          const { isPromotion, aleartTips, tips } = res.data
          this.isPromotionNum = isPromotion
          this.aleartTips = aleartTips
          this.tips = tips
        }
      })
    },

    // 提交订单
    getCreateOilOrder() {
      if (this.price === '') {
        Toast('请选择价格')
        return
      }
      this.$router.push({
        path: '/placeOder',
        query: {
          payType: 2,
          id: this.query.id,
          gas_id: this.query.gas_id, // 油站id
          gas_name: this.query.gas_name,
          other_gas_id: this.query.other_gas_id,
          number: this.query.number,
          name: this.query.name,
          gun_no: this.query.gun_no,
          amount_gun: this.amount,
          pay_amount: this.payAmount,
          oil_type: this.query.oil_type,
          litre: this.litre,
          price_gun: this.query.price_gun,
          priceYfq: this.query.price_unit,
          couponsPrice: this.couponsPrice,
          plummets: this.plummets, // 优惠直降
          serviceFee: this.serviceFee, // 渠道服务费
          allowanceId: this.allowanceId // 优惠券信息
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.coupon-title {
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  font-size: 16px;
}

.coupon-btn {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 10px;
}

.coupon-null {
  position: absolute;
  left: 15px;
  top: 60px;
  right: 15px;
  bottom: 50px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 40%;
    margin-top: -20px;
  }

  span {
    font-size: 12px;
    color: #666;
  }
}

.coupon-con {
  position: absolute;
  left: 15px;
  top: 60px;
  right: 15px;
  bottom: 50px;
  overflow-y: auto;

  .item {
    height: 80px;
    margin-bottom: 10px;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    background: linear-gradient(90deg, #fa5934 0%, #fd2b46 100%);

    .item-left,
    .item-right {
      display: flex;
      color: #fff;
      flex-direction: column;
      justify-content: center;
    }

    .item-left {
      width: 100px;
      align-items: center;
      position: relative;

      strong {
        i {
          font-style: normal;
          font-size: 15px;
          padding-right: 5px;
        }

        font-size: 30px;
        font-weight: normal;
      }

      span {
        opacity: 0.7;
        font-size: 13px;
      }
    }

    .item-right {
      flex: 1;
      align-items: flex-start;
      padding-left: 30px;
      position: relative;

      .check {
        display: block;
        width: 28px;
        height: 18px;
        background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-05-27/16/yuelvhuioFOwxdVisC1622102926.png") #fff no-repeat;
        border-radius: 0 10px 0 10px;
        position: absolute;
        right: 10px;
        top: 10px;
        background-size: 100%;
      }

      strong {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: normal;
      }

      span {
        opacity: 0.7;
        font-size: 12px;
      }
    }

    .item-left::before {
      content: "";
      position: absolute;
      right: 0;
      width: 0.5px;
      height: 60px;
      background: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0));
    }
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    .picture-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .picture {
        display: block;
        width: 280px;
        // height: 360px;
        background: rebeccapurple;
        margin: 0 auto;
      }

      .btn {
        width: 290px;
        height: 44px;
        border-radius: 22px;
        background: #ed2e2e;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        margin-top: 15px;
      }
    }
  }
}

  .content {
    padding: 10px;
    background-color: #f8f8f8;
    min-height: 100vh;

    .ophead {
      height: 40px;
    }

    .ophead.vs {
      transition: height 0.6s ease-in-out;
      height: 0;
      display: none;
    }

    .order_details {
      border-radius: 7px;
      background-color: #fff;
      padding: 5px 10px;

      & > div {
        padding: 5px 0;
      }

      .or_title {
        color: #333;
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid #eee;
        font-size: 17px;
      }

      & > ul {
        & > li {
          display: flex;
          align-items: center;
          padding: 5px 0;
          border-bottom: 1px solid #eee;
          line-height: 30px;
          color: #333;
          font-size: 13px;

          & > span:nth-child(1) {
            flex: 14;
            text-align: left;

            & > input {
              margin-left: 10px;
              font-size: 13px;
              color: #f7263c;
              font-weight: 600;
            }

            & > input::-webkit-input-placeholder {
              color: #999;
            }
          }

          & > span:nth-child(2) {
            // flex: 10;
            text-align: right;
          }
        }

        & > li:nth-last-child(1) {
          border: 0;
        }
      }
    }

    .Discount {
      margin-top: 10px;

      .dis_list {
        & > li {
          span:nth-child(2) {
            color: #f7263c;
            font-size: 17px;
            font-weight: 500;
          }

          // span.coupon-text {
          //   color: #666;
          //   font-size: 12px;
          // }
          span i {
            font-size: 13px;
            color: #666;
            font-style: normal;
          }
        }
      }
    }

    .rule {
      margin: 10px;
      text-align: left;
      display: flex;
      align-items: center;

      & > span {
        color: #333;
        font-size: 12px;
        padding: 2px;
      }
    }

    .Price {
      & > ul {
        display: flex;

        & > li {
          color: #999999;
          border: 1px solid #999999;
          border-radius: 5px;
          padding: 10px;
          width: 25%;
          margin-right: 10px;
        }

        & > li.active {
          background-color: #f7263c;
          color: #fff;
          border: 1px solid #f7263c;
        }
      }
    }

    .total {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;

      .tot_ok {
        background-color: #f7263c;
        color: #fff;
        padding: 15px;
      }

      .total_cen {
        background-color: #fff;

        .cen_le {
          align-items: center;

          .cen_t1 {
            font-size: 15px;
            color: #333;
          }

          .cen_t2 {
            color: #f7263c;
            font-size: 17px;
            font-weight: 600;
            text-align: justify;
            text-justify: newspaper;
            word-break: break-all;
          }

          .total_box {
            padding: 20px;

            & > ul {
              & > li {
                display: flex;
                padding: 10px;
                color: #333;

                & > span:nth-child(1) {
                  flex: 12;
                  text-align: left;
                  font-size: 15px;
                }

                & > span:nth-child(2) {
                  flex: 12;
                  text-align: right;
                  color: #fd2b46;
                }
              }
            }
          }
        }
      }
    }
  }

  .text-tip {
    font-size: 12px;
    padding: 6px 10px;
    text-align: left;
    background: #ffd2d2;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  /*距离弹窗*/

  .confir_box {
    background-color: #fff;
    position: relative;

    .confir_con {
      padding: 30px 10px 20px;

      .confir_txt3 {
        padding: 10px;
        display: flex;
        align-items: center;
        color: #333;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
      }
    }

    .ok_box {
      display: flex;
      align-items: center;

      & > div {
        padding: 15px;
      }

      & > div:nth-child(1) {
        flex: 12;
        color: #333333;
        background-color: #e9e9ea;
      }

      & > div:nth-child(2) {
        flex: 12;
        background-color: #f7263c;
        color: #fff;
      }
    }
  }
</style>
<style>
.van-ellipsis {
  color: #f7263c !important;
  font-size: 10px !important;
}
</style>
